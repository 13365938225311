<template>
    <div>
        <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-form @submit.prevent="tryToLogIn">
            <v-card-text>
                <v-text-field
                    v-model="email"
                    label="E-mail"
                    type="text"
                    required
                    :error-messages="emailErrors"
                    @input="$v.email.$touch(); isAuthError = false"
                    @blur="$v.email.$touch()"
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    label="Password"
                    type="password"
                    :error-messages="passwordErrors"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">Login</v-btn>
            </v-card-actions>
        </v-form>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { authMethods } from '@/store/helpers';

export default {
    computed: {
        passwordErrors() {
            const errors = [];
            if (!this.$v.password.$dirty) return errors;
            !this.$v.password.required && errors.push('Password is required.');

            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;

            this.isAuthError &&
                errors.push('These credentials do not match our records.');
            !this.$v.email.email && errors.push('Must be valid e-mail.');
            !this.$v.email.required && errors.push('E-mail is required.');

            return errors;
        }
    },

    data() {
        return {
            email: '',
            password: '',
            isAuthError: false
        };
    },

    validations: {
        email: { required, email },
        password: { required }
    },

    methods: {
        ...authMethods,
        async tryToLogIn() {
            this.isAuthError = false;

            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.logIn({
                    email: this.email,
                    password: this.password
                });

                // Redirect to the originally requested page, or to the home page
                const redirectRoute =
                    this.$route.query.redirectFrom &&
                    this.$route.query.redirectFrom !== '/logout' &&
                    this.$route.query.redirectFrom !== '/404'
                        ? this.$route.query.redirectFrom
                        : { name: 'dashboard' };

                this.$router.push(redirectRoute);
            } catch (error) {
                console.error(error);

                if (error.response.status === 401) {
                    this.isAuthError = true;

                    return;
                }

                this.$toasted.error('Something went wrong!');
            }
        }
    }
};
</script>

<style>
</style>